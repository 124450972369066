<template>
  <sb-page-content title="Fund Allocation Request">
    <sb-dialog-add-Transaction :show-dialog="showTransactionDialog"
                               :journal-entity="journalEntity"
                               @close="onModalClose"
                               @journal-item="addJournalItem" />
    <ValidationObserver ref="obs">
      <sb-card min-width="100%"
               :loading="$wait.is('serviceRequestLoading')">
        <v-container>
          <v-row>
            <v-col v-if="fundAllocationRequestNumber"
                   cols="12">
              <Sb-fund-allocation-list-item :loading="$wait.is('serviceRequestLoading')"
                                            :item="fundAllocation"
                                            :show-refresh="showRefresh"
                                            no-click
                                            @refresh-fund-allocation="refreshFundAllocation" />
            </v-col>
          </v-row>
          <v-row v-if="canUpdateFundAllocation"
                 align="start">
            <v-col cols="12"
                   sm="6">
              <sb-file-upload-field v-model="template"
                                    placeholder="Upload Fund Allocation Templete"
                                    :file-types="'.xlsx'"
                                    label="Upload Fund Allocation Templete" />
            </v-col>
            <v-col cols="auto">
              <v-btn x-large
                     :disabled="!canUpload"
                     color="secondary"
                     @click="uploadTemplate">
                Upload
                <v-icon right
                        dark>
                  mdi-cloud-upload
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="canUpdateFundAllocation"
                 align="start">
            <v-col cols="12"
                   class="text-right">
              <v-btn text
                     large
                     color="primary"
                     @click="showTransactionDialog=true">
                <v-icon>mdi-plus-circle-outline </v-icon>
                add transaction
              </v-btn>
            </v-col>
          </v-row>
          <v-toolbar dark
                     color="secondary"
                     class="toolbar-rounded search-padding">
            <v-text-field v-model="search"
                          clearable
                          flat
                          solo
                          light
                          hide-details
                          prepend-inner-icon="mdi-magnify"
                          label="Search" />
            <v-spacer />
          </v-toolbar>
          <sb-data-table :headers="FUND_ALLOCATION_TABLE_HEADERS"
                         :items="fundAllocation.fundAllocation.journals"
                         :show-actions="canUpdateFundAllocation"
                         :show-errors="true"
                         :loading="$wait.is('getServiceRequestLoading')"
                         :show-erros-as-list="true"
                         default-pagination
                         empty-message="No fund allocations"
                         empty-sub-message="No fund allocations available at this time"
                         @selected-item="selectedItem" />
          <v-row>
            <v-col cols="12"
                   sm="6"
                   class="py-0">
              <sb-text-field v-model="fundAllocation.fundAllocation.oemEmailAddress"
                             :disabled="!canUpdateFundAllocation"
                             rules="email"
                             label="Original client/OEM allocation confirmation e-mail address" />
            </v-col>
          </v-row>
        </v-container>
      </sb-card>

      <sb-journal-supporting-documents v-if="fundAllocation.fundAllocation.journals.length > 0"
                                       :attachments="fundAllocationDocuments"
                                       :show-document-list="showDocumentList"
                                       :document-entity.sync="fundAllocationDocument"
                                       :show-fund-payout-fields="showFundPayoutFields"
                                       :is-multiple-file-selection="isMultipleFileSelection"
                                       :is-loading="$wait.is('getAllAttachmentsLoading')"
                                       :is-downloading="$wait.is('attachmentsLoading')"
                                       @download-document="downloadDocument" />
      <sb-approve-or-decline-transaction v-if="canApproveOrDecline"
                                         @decline="declineFundAllocationRequest"
                                         @approve="approveFundAllocationRequest" />
      <v-container v-if="canUpdateFundAllocation">
        <div class="text-right">
          <v-btn v-if="canCancelFundAllocation"
                 class="ma-2"
                 color="secondary"
                 large
                 depressed
                 :loading="$wait.is('serviceRequestLoading') || $wait.is('uploadAttachmentsLoading') || $wait.is('updateServiceRequestExLoading')"
                 @click="cancelFundAllocation">
            Cancel
          </v-btn>
          <v-btn class="ma-2"
                 :disabled="fundAllocation.fundAllocation.journals.length === 0 "
                 color="secondary"
                 large
                 depressed
                 :loading="$wait.is('serviceRequestLoading') || $wait.is('uploadAttachmentsLoading') || $wait.is('updateServiceRequestExLoading')"
                 @click="saveFundAllocation">
            Save
          </v-btn>
          <v-btn class="ma-2"
                 :disabled="disableSubmitButton || fundAllocation.fundAllocation.journals.length === 0 "
                 color="secondary"
                 large
                 depressed
                 :loading="$wait.is('serviceRequestLoading') || $wait.is('uploadAttachmentsLoading') || $wait.is('updateServiceRequestExLoading')"
                 @click="submitFundAllocation">
            Submit
          </v-btn>
        </div>
      </v-container>
      <v-expand-transition>
        <v-row v-if="showAudit">
          <v-col cols="12"
                 class="py-0">
            <div v-if="showAudit"
                 class="text-h2 my-5">
              Audit Trail
            </div>
          </v-col>
          <v-col v-if="showAudit"
                 cols="12">
            <sb-audit-trail :is-loading="$wait.is('serviceRequestLoading')"
                            :audit-logs="fundAllocation.audit.auditLog" />
          </v-col>
        </v-row>
      </v-expand-transition>
      <v-row v-if="fundAllocation.serviceRequestNumber"
             justify="center">
        <v-btn color="secondary"
               large
               outlined
               @click=" ()=> {showAudit = !showAudit; showEvents = false}">
          {{ showAudit ? 'Show Less Audit Trail' : 'Show Audit Trail' }}
        </v-btn>
      </v-row>
    </ValidationObserver>
  </sb-page-content>
</template>
<script>
import SbApproveOrDeclineTransaction from '@/components/fundAllocation/SbApproveOrDeclineTransaction';
import SbDialogAddTransaction from '@/components/fundAllocation/SbDialogAddTransaction';
import SbJournalSupportingDocuments from '@/components/fundAllocation/SbJournalSupportingDocuments';
import SbDataTable from '@/components/core/SbDataTable';
import {
  FUND_ALLOCATION_TABLE_HEADERS,
  JOURNAL_ENTITY_TYPE,
  SR_APPLICATION_TYPES,
  SR_STATUSES,
  FUND_ALLOCATION_DOC_TYPE,
  APPROVAL_STATUSES,
} from '@/config/constants';
import { mapGetters, mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import { ValidationObserver } from 'vee-validate';
import moment from 'moment';
import Pagination from '@/models/Pagination';
import SbFundAllocationListItem from '@/components/fundAllocation/SbFundAllocationListItem';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import Audit from '@/models/Audit';
import SbAuditTrail from '@/widgets/event/SbAuditTrail';
import ServiceRequest from '../../../models/ServiceRequest';
import FundAllocationDocument from '../../../models/fundAllocation/FundAllocationDocument';
import FundAllocation from '../../../models/serviceRequest/FundAllocation';
import ServiceRequestAction from '../../../models/ServiceRequestAction';

export default {
  components: {
    SbApproveOrDeclineTransaction,
    SbDialogAddTransaction,
    SbJournalSupportingDocuments,
    SbDataTable,
    SbFundAllocationListItem,
    ValidationObserver,
    SbAuditTrail,
  },
  props: {
    fundAllocationRequestNumber: {
      type: String,
      default: undefined,
    },
  },
  data: () => ({
    search: '',
    showTransactionDialog: false,
    FUND_ALLOCATION_TABLE_HEADERS,
    template: [],
    canUpload: false,
    showFundPayoutFields: false,
    isMultipleFileSelection: false,
    journalEntity: {},
    previousJournalEntity: {},
    selectedAction: '',
    selectedItemIdex: -1,
    serviceRequest: new ServiceRequest(),
    fundAllocationDocument: new FundAllocationDocument(),
    fundAllocation: new FundAllocation(),
    pagination: new Pagination(),
    updatedDocuments: [],
    fundAllocationDocuments: [],
    showDocumentList: false,
    showAudit: false,
    documentsList: [],
  }),

  computed: {
    ...mapGetters({
      numberOfElements: 'userSettings/numberOfElements',
    }),
    canApproveOrDecline() {
      return (
        this.authUser.isFundOperationsTeamLeader &&
        this.fundAllocationRequestNumber !== undefined &&
        this.fundAllocation.status.key === SR_STATUSES.SR_STATUS_PENDING_APPROVAL.value &&
        this.authUser.username !== this.fundAllocation.audit.createdById
      );
    },
    canUpdateFundAllocation() {
      return (
        (this.authUser.isFundOperationsTeamMember &&
          !this.readOnly &&
          this.authUser.username === this.fundAllocation.audit.createdById &&
          this.fundAllocation.status.key !== SR_STATUSES.SR_STATUS_CANCELLED.value) ||
        this.fundAllocationRequestNumber === undefined ||
        this.fundAllocation.status.key === SR_STATUSES.SR_STATUS_AWAITING_DOCUMENTS.value
      );
    },
    canCancelFundAllocation() {
      return (
        this.authUser.username === this.fundAllocation.audit.createdById &&
        this.fundAllocationRequestNumber !== undefined &&
        (this.fundAllocation.status.key === SR_STATUSES.SR_STATUS_SAVED.value ||
          this.fundAllocation.status.key === SR_STATUSES.SR_STATUS_DECLINED.value ||
          this.fundAllocation.status.key === SR_STATUSES.SR_STATUS_VALIDATION_FAILED.value ||
          this.fundAllocation.status.key === SR_STATUSES.SR_STATUS_IN_PROGRESS.value)
      );
    },
    readOnly() {
      return (
        this.fundAllocation.status.key === SR_STATUSES.SR_STATUS_COMPLETED.value ||
        this.fundAllocation.status.key === SR_STATUSES.SR_STATUS_PENDING_APPROVAL.value ||
        this.fundAllocation.status.key === SR_STATUSES.SR_STATUS_PENDING_PROCESSING.value ||
        this.fundAllocation.status.key === SR_STATUSES.SR_STATUS_CANCELLED.value ||
        this.fundAllocation.status.key === SR_STATUSES.SR_STATUS_IN_PROGRESS.value
      );
    },
    // TO Do: Temp Solution:Fix sbFileUploadField validationObserver
    disableSubmitButton() {
      return this.fundAllocationDocument.oemRequest.length === 0;
    },
    showRefresh() {
      return (
        this.fundAllocation.status.key === SR_STATUSES.SR_STATUS_IN_PROGRESS.value ||
        this.fundAllocation.status.key === SR_STATUSES.SR_STATUS_AWAITING_DOCUMENTS.value ||
        this.fundAllocation.status.key === SR_STATUSES.SR_STATUS_PENDING_APPROVAL.value ||
        this.fundAllocation.status.key === SR_STATUSES.SR_STATUS_PENDING_PROCESSING.value
      );
    },
  },

  watch: {
    template() {
      if (this.template.length > 0) {
        this.canUpload = !this.canUpload;
      } else {
        this.canUpload = false;
      }
    },
    'fundAllocation.fundAllocation.journals': {
      handler() {
        const journalEntityTypes = [JOURNAL_ENTITY_TYPE.TYPE_C.key, JOURNAL_ENTITY_TYPE.TYPE_F.key];
        this.showFundPayoutFields = this.fundAllocation.fundAllocation.journals.some(
          (value) => value.journalEntityType.key === JOURNAL_ENTITY_TYPE.TYPE_C.key,
        );
        /* eslint-disable */
        this.isMultipleFileSelection = journalEntityTypes.every((value) =>
          this.fundAllocation.fundAllocation.journals.some((e) => e.journalEntityType.key === value),
        );
      },
    },
    fundAllocationRequestNumber: {
      handler(value) {
        this.displayFundAllocation(value);
      },
      immediate: true,
    },
    'fundAllocationDocument.oemRequest': {
      handler(newValue, oldValue) {
        if (this.canAddToDocumentUpdateList(newValue, oldValue)) {
          this.updatedDocuments.push({ fileType: FUND_ALLOCATION_DOC_TYPE.OEMREQUEST, files: newValue });
        }
      },
    },
    'fundAllocationDocument.proofOfBankAccount': {
      handler(newValue, oldValue) {
        if (this.canAddToDocumentUpdateList(newValue, oldValue)) {
          this.updatedDocuments.push({ fileType: FUND_ALLOCATION_DOC_TYPE.PROOFOFBANKACCOUNT, files: newValue });
        }
      },
    },
    'fundAllocationDocument.authorisationLetter': {
      handler(newValue, oldValue) {
        if (this.canAddToDocumentUpdateList(newValue, oldValue)) {
          this.updatedDocuments.push({ fileType: FUND_ALLOCATION_DOC_TYPE.AUTHORISATIONLETTER, files: newValue });
        }
      },
    },
    'fundAllocationDocument.bolPayoutRequest': {
      handler(newValue, oldValue) {
        if (this.canAddToDocumentUpdateList(newValue, oldValue)) {
          this.updatedDocuments.push({ fileType: FUND_ALLOCATION_DOC_TYPE.BOLPAYOUTREQUEST, files: newValue });
        }
      },
    },
    'fundAllocationDocument.proofOfPayment': {
      handler(newValue, oldValue) {
        if (this.canAddToDocumentUpdateList(newValue, oldValue)) {
          this.updatedDocuments.push({ fileType: FUND_ALLOCATION_DOC_TYPE.PROOFOFPAYMENT, files: newValue });
        }
      },
    },
  },

  methods: {
    ...mapActions('fundAllocations', [
      'uploadFundAllocationTemplate',
      'addTransactionItem',
      'acknowledgeDuplicateTransactions',
      'editTransactionItem',
      'removeTransationItem',
    ]),
    
    ...mapActions('serviceRequests', ['createServiceRequest', 'getServiceRequest', 'updateServiceRequestEx', 'actionServiceRequest']),
    ...mapActions('attachments', ['getAttachment', 'uploadAttachments', 'getAllAttachments']),

    ...mapWaitingActions('serviceRequests', {
      createServiceRequest: 'serviceRequestLoading',
      getServiceRequest: 'getServiceRequestLoading',
      updateServiceRequestEx: 'updateServiceRequestExLoading',
    }),

    ...mapWaitingActions('attachments', {
      uploadAttachments: 'uploadAttachmentsLoading',
      getAttachment: 'attachmentsLoading',
      getAllAttachments: 'getAllAttachmentsLoading',
    }),

    onModalClose() {
      this.showTransactionDialog = !this.showTransactionDialog;
      this.journalEntity = {};
    },

    async addJournalItem(item) {
      let journals = [];
      const payload = {
        item,
        fundAllocation: this.fundAllocation.fundAllocation,
        selectedItemIndex: this.selectedItemIdex,
        previousItem: this.previousJournalEntity,
      };
      if (this.selectedAction === 'Edit') {
        journals = await this.editTransactionItem(payload);
        this.editOrAddJournal(journals);
        this.selectedAction = '';
        return;
      }
      journals = await this.addTransactionItem(payload);
      this.editOrAddJournal(journals);
    },

    async uploadTemplate() {
      const payload = { files: this.template, fundAllocation: this.fundAllocation.fundAllocation };
      const results = await this.uploadFundAllocationTemplate(payload);
      this.template = [];
      this.fundAllocation.fundAllocation.journals = results;
    },

    async selectedItem(selectedItem) {
      const copySelectedItem = JSON.parse(JSON.stringify(selectedItem));
      this.selectedAction = copySelectedItem.selectedAction;
      this.selectedItemIdex = copySelectedItem.selectedIndex;
      const payload = {
        item: copySelectedItem.selectedItem,
        fundAllocation: this.fundAllocation.fundAllocation,
        selectedItemIndex: this.selectedItemIdex,
      };
      await this.handleSelectedItem(copySelectedItem, payload);
    },

    async saveFundAllocation() {
      if (this.fundAllocation.serviceRequestNumber) {
        await this.updateFundAllocation(SR_STATUSES.SR_STATUS_SAVED.value);
      } else {
        await this.createFundAllocation(SR_STATUSES.SR_STATUS_SAVED.value);
      }
    },

    async submitFundAllocation() {
      if (this.fundAllocation.serviceRequestNumber) {
        await this.updateFundAllocation(SR_STATUSES.SR_STATUS_IN_PROGRESS.value);
      } else {
        await this.createFundAllocation(SR_STATUSES.SR_STATUS_IN_PROGRESS.value);
      }
    },

    async cancelFundAllocation() {
      await this.updateFundAllocation(SR_STATUSES.SR_STATUS_CANCELLED.value);
    },

    async createFundAllocation(fundAllocationStatus) {
      const currentDate = moment().format('YYYY-MM-DD');
      const currentTime = moment().format('HH:mm:ss');
      this.updatedDocuments = [];
      this.fundAllocation.applicationType.key = SR_APPLICATION_TYPES.SR_APPLICATION_TYPE_FUND_ALLOCATION;
      this.fundAllocation.createdTimestamp = `${currentDate} ${currentTime}`;
      this.fundAllocation.status.key = fundAllocationStatus;

      const serviceRequestNumber = await this.createServiceRequest(this.fundAllocation);
      if (serviceRequestNumber) {
        await this.uploadDocuments(serviceRequestNumber);
        await this.displayFundAllocation(serviceRequestNumber);
        await this.updateFundAllocationServiceRequest(this.fundAllocation);
        this.$router.push({
          name: 'FundAllocationsViewFundAllocation',
          params: { fundAllocationRequestNumber: serviceRequestNumber },
        });
      }
    },

    async uploadDocuments(serviceRequestNumber) {
      /* eslint-disable */
      for (const [key, value] of Object.entries(this.fundAllocationDocument)) {
        if (value.length > 0) {
          const fileType = FUND_ALLOCATION_DOC_TYPE[key.toUpperCase()];
          const payload = {
            fileType,
            files: value,
            serviceRequestId: serviceRequestNumber,
          };
          await this.uploadFundAllocationDocuments(payload, fileType);
        }
      }
    },

    async uploadUpdatedDocuments() {
      if (this.updatedDocuments.length > 0) {
        for (const doc of this.updatedDocuments) {
          const payload = {
            fileType: doc.fileType,
            files: doc.files,
            serviceRequestId: this.fundAllocation.serviceRequestNumber,
          };
          await this.uploadFundAllocationDocuments(payload, doc.fileType);
        }
      }
      this.updatedDocuments = [];
    },

    async uploadFundAllocationDocuments(payload, key) {
      const response = await this.uploadAttachments(payload);
      if (response.length === 0) {
        this.documentsList.push({
          key,
          uploaded: false,
        });
      }
    },

    async displayFundAllocation(value) {
      this.showDocumentList = false;
      this.serviceRequest = new ServiceRequest();
      this.fundAllocationDocument = new FundAllocationDocument();
      this.fundAllocation = new FundAllocation();
      if (value) {
        await this.getFundAllocationDocuments(value);
        const request = await this.getServiceRequest(value);
        this.fundAllocation.id = request.id;
        this.fundAllocation.fundAllocation = request.fundAllocation;
        this.fundAllocation.serviceRequestNumber = request.serviceRequestNumber;
        this.fundAllocation.applicationType = request.applicationType;
        this.fundAllocation.createdTimestamp = request.createdTimestamp;
        this.fundAllocation.status = request.status;
        this.fundAllocation.audit = request.audit;
        this.fundAllocation.closedTimestamp = request.closedTimestamp;
        if (!this.canUpdateFundAllocation) {
          this.showDocumentList = true;
        }
      }
    },

    async handleSelectedItem(selectedItem, payload) {
      let journals = [];
      if (this.selectedAction === 'Edit') {
        this.showTransactionDialog = true;
        this.journalEntity = { ...selectedItem.selectedItem };
        this.previousJournalEntity = JSON.parse(JSON.stringify(selectedItem.selectedItem));
        return;
      }
      if (this.selectedAction === 'Acknowledge Duplicate') {
        journals = await this.acknowledgeDuplicateTransactions(payload);
      }
      if (this.selectedAction === 'Remove') {
        journals = await this.removeTransationItem(payload);
      }
      this.fundAllocation.fundAllocation.journals = journals;
    },

    editOrAddJournal(journals) {
      this.fundAllocation.fundAllocation.journals = journals;
    },

    async updateFundAllocation(fundAllocationStatus) {
      if (fundAllocationStatus) {
        this.fundAllocation.status.key = fundAllocationStatus;
      }
      await this.uploadUpdatedDocuments();
      await this.updateFundAllocationServiceRequest(this.fundAllocation);
      await this.displayFundAllocation(this.fundAllocation.serviceRequestNumber);
    },

    async updateFundAllocationServiceRequest(payloadFundAllocation) {
      const payload = {
        serviceRequest: payloadFundAllocation,
        audit: new Audit(),
      };
      if (this.documentsList.length > 0) {
        const anyFailedDocuments = this.documentsList.some((element) => element.uploaded === false);
        if (anyFailedDocuments) {
          payloadFundAllocation.fundAllocation.documentsReceived = false;
        } else {
          payloadFundAllocation.fundAllocation.documentsReceived = true;
        }
      } else {
        payloadFundAllocation.fundAllocation.documentsReceived = true;
      }
      await this.updateServiceRequestEx(payload);
      this.documentsList = [];
    },

    async getFundAllocationDocuments(serviceRequestNumber) {
      this.fundAllocationDocument = new FundAllocationDocument();
      const payload = {
        serviceRequestId: serviceRequestNumber,
      };
      this.getAllAttachments(payload).then(async (response) => {
        if (response) {
          this.fundAllocationDocuments = response.documents;
          for (const doc of response.documents) {
            const File = {
              lastModifiedDate: doc.lastModifiedDate,
              name: doc.filename,
              size: doc.contentLength,
              type: doc.contentType,
              webkitRelativePath: '',
            };

            this.mapFundAllocationDocument(File, doc.fileType);
          }
        }
      });
    },

    mapFundAllocationDocument(file, fileType) {
      if (fileType === FUND_ALLOCATION_DOC_TYPE.OEMREQUEST) {
        this.fundAllocationDocument.oemRequest.push(file);
      }
      if (fileType === FUND_ALLOCATION_DOC_TYPE.PROOFOFBANKACCOUNT) {
        this.fundAllocationDocument.proofOfBankAccount.push(file);
      }
      if (fileType === FUND_ALLOCATION_DOC_TYPE.AUTHORISATIONLETTER) {
        this.fundAllocationDocument.authorisationLetter.push(file);
      }
      if (fileType === FUND_ALLOCATION_DOC_TYPE.BOLPAYOUTREQUEST) {
        this.fundAllocationDocument.bolPayoutRequest.push(file);
      }
      if (fileType === FUND_ALLOCATION_DOC_TYPE.PROOFOFPAYMENT) {
        this.fundAllocationDocument.proofOfPayment.push(file);
      }
    },

    canAddToDocumentUpdateList(newValue, oldValue) {
      return !isEqual(sortBy(newValue), sortBy(oldValue)) && newValue.length > 0;
    },

    async downloadDocument(attachmentId) {
      await this.getAttachment(attachmentId);
    },
    async approveFundAllocationRequest() {
      await this.actionFundAllocationServiceRequest(APPROVAL_STATUSES.APPROVED.value);
    },

    async declineFundAllocationRequest(reasons) {
      await this.actionFundAllocationServiceRequest(APPROVAL_STATUSES.DECLINED.value, reasons);
    },

    async actionFundAllocationServiceRequest(action, reasons) {
      const payload = new ServiceRequestAction();
      if (reasons) {
        payload.reasons = reasons;
      }
      payload.serviceRequestNumber = this.fundAllocation.serviceRequestNumber;
      payload.action = action;
      payload.applicationType.key = SR_APPLICATION_TYPES.SR_APPLICATION_TYPE_FUND_ALLOCATION;
      await this.actionServiceRequest(payload);
      this.$router.push({
        name: 'FundAllocationsInbox',
      });
    },

    async refreshFundAllocation(serviceRequestNumber) {
      await this.displayFundAllocation(serviceRequestNumber);
    },
  },
};
</script>

