import ServiceRequest from './ServiceRequest';
import FundAllocationDetails from '../fundAllocation/FundAllocationDetails';

export default class FundAllocation extends ServiceRequest {
  constructor(existingObject) {
    super(existingObject);
    const object = existingObject || {};

    this.fundAllocation = new FundAllocationDetails(object.fundAllocation);
  }
}
