import ReferenceList from '../ReferenceList';
import Audit from '../Audit';

export default class ServiceRequest {
  constructor(existingObject) {
    const object = existingObject || {};

    this.id = object.id || '';
    this.serviceRequestNumber = object.serviceRequestNumber || undefined;
    this.applicationType = new ReferenceList(object.applicationType);
    this.serviceType = new ReferenceList(object.serviceType);
    this.requestType = new ReferenceList(object.requestType);
    this.status = new ReferenceList(object.status);
    this.createdTimestamp = object.createdTimestamp || undefined;
    this.lastModifiedTimestamp = object.lastModifiedTimestamp || undefined;
    this.closedTimestamp = object.closedTimestamp || undefined;
    this.audit = new Audit(object.audit);
  }
}
