export default class FundAllocationDocument {
  constructor(existingObject) {
    const object = existingObject || {};

    this.oemRequest = object.oemRequest || [];
    this.proofOfBankAccount = object.proofOfBankAccount || [];
    this.authorisationLetter = object.authorisationLetter || [];
    this.bolPayoutRequest = object.bolPayoutRequest || [];
    this.proofOfPayment = object.proofOfPayment || [];
  }
}
