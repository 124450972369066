import { getCurrentTimestamp } from '@/utils';
import ReferenceList from './ReferenceList';

export default class ServiceRequestAction {
  constructor(existingObject) {
    const object = { ...existingObject };

    this.serviceRequestNumber = object.serviceRequestNumber || undefined;
    this.action = object.action || undefined;
    this.reasons = object.reasons || [];
    this.reason = object.reason || undefined;
    this.username = object.username || 'Unknown';
    this.timestamp = object.timestamp || getCurrentTimestamp();
    this.applicationType = new ReferenceList(object.applicationType);
  }
}
